<template>
  <BaseModal name="modal-permission-group" size="lg" :title="$t('seller.settings.modal_permission_group.text_754')"
    @shown="openModal" @hidden="reset">
    <b-row>
      <b-col cols="12">
        <b-form novalidate v-if="!loading">
          <b-row class="mb-3">
            <b-col cols="6">
              <b-form-group :label="$t('seller.settings.modal_permission_group.text_755')" label-for="date-in">
                <b-form-input id="name" name="name" v-model="name" type="text" v-validate="'required'" :placeholder="$t('seller.settings.modal_permission_group.text_2657')
                  "></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t("seller.settings.modal_permission_group.text_2658") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <div class="grid-list-itens">
                <div>
                  <h5 class="font-weight-bold">{{ $t('seller.settings.modal_permission_group.text_2659') }}</h5>
                </div>
                <!-- <div>
                  <img :src="getImgUrlPermissions('view')" :title="$t('seller.settings.modal_permission_group.view')" :alt="$t('seller.settings.modal_permission_group.view')" style="height: 70%; width: auto" class="float-right mr-2" />
                </div> -->
                <div>
                  <img :src="getImgUrlPermissions('edit')" :title="$t('seller.settings.modal_permission_group.edit')"
                    :alt="$t('seller.settings.modal_permission_group.edit')" style="height: 70%; width: auto"
                    class="float-right mr-2" />
                </div>
              </div>
              <b-form-group label-for="date-in">
                <div>
                  <!-- select all permissions checkbox -->
                  <div class="grid-list-itens border-bottom">
                    <div>
                      <div class="d-flex itens">
                        <img :src="getImgUrlPermissions('store')" title="Selecionar todos" alt="Selecionar todos" />

                        <div>
                          <p>Selecionar todos</p>
                          <span>
                            Habilita/desabilita todas as permissões disponíveis
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- <div>
                      <b-form-checkbox
                        v-model="permissionsOptions.all_views"
                        @change="checkAll('all_views')"
                        name="check-button"
                        switch
                        size="lg"
                        class="ml-switch"
                      ></b-form-checkbox>
                    </div> -->
                    <div>
                      <b-form-checkbox v-model="permissionsOptions.all_edits" @change="checkAll('all_edits')"
                        name="check-button" switch size="lg" class="ml-switch"></b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="grid-list-itens border-bottom" v-for="(permission, index) in permissions" :key="index">
                  <div>
                    <div class="d-flex itens">
                      <img :src="getImgUrlMenus(permission.name.toLowerCase())"
                        :title="$t('seller.settings.modal_permission_group.' + permission.title)"
                        :alt="$t('seller.settings.modal_permission_group.' + permission.title)" />

                      <div>
                        <p>{{ $t('seller.settings.modal_permission_group.' + permission.title) }}</p>
                        <span>{{ permission.description }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-for="(type, key) in permission.types" :key="index + key">
                    <b-form-checkbox name="check-button" v-model="permission.types[key]"
                      @change="verifyCheckAll(permission, $event)" switch :disabled="type === 'not_yet_available'"
                      size="lg" class="ml-switch"></b-form-checkbox>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div v-if="loading" class="
            Table-body
            py-4
            d-flex
            justify-content-center
            align-items-center
          ">
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="secondary" :disabled="loading" @click="onSubmit">
        Salvar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import SubUsersPermissionsService from "@/services/resources/SubUsersPermissionsService";
const serviceSubUsersPermissions = SubUsersPermissionsService.build();
import SubUsersGroupService from "@/services/resources/SubUsersGroupService";
const serviceSubGroup = SubUsersGroupService.build();
// import SubUsersService from "@/services/resources/SubUsersService";
// const serviceSubUsers = SubUsersService.build();

import Multiselect from "vue-multiselect";

export default {
  name: "ModalPermissionGroup",
  props: ["allData"],
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: true,
      name: "",
      permissions: {},
      permissionsOptions: {
        all_views: false,
        all_edits: false,
      },
    };
  },
  methods: {
    checkAll(checked) {
      // Crie uma cópia profunda das permissões
      let newPermissions = JSON.parse(JSON.stringify(this.permissions));

      // Atualize as permissões com base no tipo selecionado (view ou edit)
      newPermissions.forEach((element) => {
        if (checked === "all_views" && element.types.view !== "not_yet_available") {
          element.types.view = this.permissionsOptions[checked];
        } else if (checked === "all_edits" && element.types.edit !== "not_yet_available") {
          element.types.edit = this.permissionsOptions[checked];
        }
      });

      // Atualize o estado das permissões
      this.permissions = newPermissions;
    },
    verifyCheckAll(permission = null, checked = null) {
      let view_permissions = this.permissions.map((x) => x.types.view !== "not_yet_available");
      let view_permissions_count = view_permissions.filter(item => item).length;
      let edit_permissions = this.permissions.map((x) => x.types.edit !== "not_yet_available");
      let edit_permissions_count = edit_permissions.filter(item => item).length;
      let permissions_views = 0;
      let permissions_edits = 0;
      this.permissions.forEach((element, index) => {
        if (element.types.view === true) {
          permissions_views++;
        }
        if (element.types.edit === true) {
          permissions_edits++;
        }
      });
      this.permissionsOptions.all_views = permissions_views === view_permissions_count;
      this.permissionsOptions.all_edits = permissions_edits === edit_permissions_count;
      this.alertWhenAllowing(permission, checked);
    },
    alertWhenAllowing(permission, checked) {
      if (!permission) {
        return;
      }

      if (permission.name === 'Play' && checked) {
        this.$bvToast.toast(this.$t('seller.settings.modal_permission_group.text_759'), {
          variant: 'warning',
          title: this.$t('seller.settings.modal_permission_group.text_758'),
          autoHideDelay: 7000,
          appendToast: true,
        });
      }
    },
    /**
     * Validates whether "Play" permission is enabled only with "Dashboard" or "Products".
     *
     * @param {Array<Object>} permissions - The list of permission objects to validate.
     * @param {string} permissions[].name - The name of the permission (e.g., "Play", "Dashboard", "Products").
     * @returns {boolean} - Returns `true` if the permissions are valid, otherwise `false`.
     */
    validatePlayPermission(permissions) {
      const hasPlay = permissions.some((permission) => permission.name === 'Play');
      if (!permissions.length || (permissions.length && !hasPlay)) {
        return true;
      }

      const hasOtherPermission = permissions.some(
        (permission) => permission.name === 'Dashboard' || permission.name === 'Products'
      );

      if (hasPlay && permissions.length === 1) {
        return false;
      }

      if (permissions.length > 1 && hasPlay && !hasOtherPermission) {
        return false;
      }
      return true;
    },
    reset() {
      this.name = "";
      this.permissions = [];
      this.permissionsOptions.all_views = false;
      this.permissionsOptions.all_edits = false;
    },
    fetchPermissions() {
      this.loading = true;

      serviceSubUsersPermissions
        .read("")
        .then((response) => {
          this.permissions = response;

          console.log("Permissions", this.permissions);
          console.log("allData", this.allData);

          if (this.allData.length !== 0) {
            this.name = this.allData.name;
            this.permissions = this.allData.permissions;
          }
          this.verifyCheckAll();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let permissions = this.permissions;
          let permission = permissions.filter((x) => x.types['view'] === true || x.types['edit'] === true);

          console.log("permission", permission);

          if (!this.validatePlayPermission(permission)) {
            this.$bvToast.toast(this.$t('seller.settings.modal_permission_group.text_761'), {
              variant: 'danger',
              title: this.$t('seller.settings.modal_permission_group.text_760'),
              autoHideDelay: 7000,
              appendToast: true,
            });
            return;
          }

          if (permission.length) {
            var id_permissions = [];
            permission.forEach((element, index) => {
              console.log("element", element);
              if (element.types['view'] === true && element.ids['view'] !== null) {
                id_permissions.push(element.ids['view']);
              } else if (element.types['edit'] === true && element.ids['edit'] !== null) {
                id_permissions.push(element.ids['edit']);
              }
            });
            console.log("id_permissions", id_permissions, permissions);

            let data = {
              name: this.name,
              permissions: id_permissions,
            };

            if (this.allData.length !== 0) {
              data.id = this.allData.id;
              serviceSubGroup
                .update(data)
                .then((response) => {
                  this.resolveResponse();
                })
                .catch((err) => {
                  console.log(err);
                });

              return;
            }

            serviceSubGroup
              .create(data)
              .then((response) => {
                this.resolveResponse();
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$bvToast.toast(
              this.$t("seller.settings.modal_permission_group.text_2661"),
              {
                title: this.$t(
                  "seller.settings.modal_permission_group.text_2660"
                ),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        }
      });
    },
    resolveResponse() {
      this.$bvToast.toast(
        this.$t("seller.settings.modal_permission_group.text_2662"),
        {
          title: this.$t("seller.settings.modal_permission_group.text_2660"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
      this.$emit("close");
      this.reset();
      this.verifyCheckAll();
      this.$root.$emit("bv::hide::modal", "modal-permission-group", "#btnShow");
    },
    openModal() {
      this.fetchPermissions();
    },
    getImgUrlPermissions(icon) {
      var images = require.context(
        "@/assets/img/permissions/",
        false,
        /\.svg$/
      );
      return images("./" + icon + ".svg");
    },
    getImgUrlMenus(icon) {
      var images = require.context(
        "@/assets/img/icons/",
        false,
        /\.svg$/
      );
      return images("./" + icon + ".svg");
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-list-itens {
  display: grid;
  grid-template-columns: calc(100% - 110px) 70px;
  /* grid-template-columns: calc(100% - 180px) 70px 70px; */
  gap: 20px;
  margin-top: 30px;

  .itens {
    gap: 20px;
    align-items: flex-start;
    padding-bottom: 10px;

    p {
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      margin-bottom: 5px;
      line-height: 1;
    }

    span {
      font-weight: 400;
      font-size: 13px;
      color: #81858e;
    }
  }
}

.ml-switch {
  margin-left: 35px;
}

h3 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

p {
  font-size: 12px;
  font-weight: 300;
}

.grid-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-input {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 3px;

  .copy {
    border: none;
    color: #333;
    background: #f7f7f7;
    outline: none !important;
    font-weight: 600;
    height: auto;
    padding: 0 42px !important;
    border-radius: 5px;
    font-size: 14px;

    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
}

.code {
  background: rgb(27, 26, 29);
  color: white;
  height: auto;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  border-radius: 3px;
}
</style>
