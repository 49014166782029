<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-end">
      <BaseHeader :title="$t('views.seller.sales.text_1333')" />
      <SelectCurrency
        v-show="interAccess"
        :currency="currency"
        @update-coin="updateCoin"
      />
    </div>

    <div class="container-table">
      <!-- Total Sales -->
      <div class="box-total">
        <p class="total-vendas info">
          {{ itens }} {{ $t('views.seller.sales.text_1334') }}
        </p>
        <div>
          <p class="total-vendas">
            {{ $t('views.seller.sales.text_1335') }}:
            <span
              >{{ currency.currency_symbol }}
              {{ total ? formatMoney(total) : '0,00' }}</span
            >
          </p>
        </div>
        <div></div>
      </div>
      <!-- Filters -->
      <div class="header-table">
        <div class="flex-sale">
          <div class="d-flex align-items-center">
            <!-- Filter -->
            <b-button id="filtro-1" class="btn-table" @click="openFilters"
              ><img src="@/assets/img/icons/u_filter.svg" />
              <span>{{ $t('views.seller.sales.text_1428') }}</span>
            </b-button>
            <!-- Download -->
            <b-button @click="exportData" class="btn-table" id="exportar-1"
              ><img src="@/assets/img/icons/u_export.svg" alt="" />
              <span>{{ $t('views.seller.sales.text_1417') }}</span>
            </b-button>
            <b-button
              @click="updateStatusAndFetchReports"
              :class="`button--${buttonReportStatus} btn-table`"
              id="estatistica-1"
              ><img src="@/assets/img/icons/u_analytics.svg" alt="" />
              <span>{{ $t('views.seller.sales.text_1420') }}</span>
            </b-button>
          </div>

          <div class="d-flex align-items-center flex-acoes">
            <!-- Date Range Filter -->
            <div class="datas">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                @update="fetchSales(search)"
                v-model="dateRange"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  :title="$t('views.seller.sales.text_1418')"
                >
                  {{ $t('views.seller.sales.text_1419') }}
                </template>
              </date-range-picker>
            </div>
            <!-- Date Type Filter -->
            <div>
              <b-form-group label-for="type">
                <b-form-select
                  id="type"
                  v-model="type_date"
                  @change="changedType"
                  :options="types_list"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
        <b-col cols="12" md="4" class="container-pesquisa">
          <div class="inputSearch">
            <img src="@/assets/img/icons/u_search.svg" class="Pointer search" />
            <input
              type="text"
              :placeholder="$t('views.seller.sales.text_1336')"
              v-on:input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
      </div>
      <!-- Revenues -->
      <div v-if="buttonReportStatus === 'black'" data-anima="right">

        <b-col v-if="userID != 14333" cols="12" class="p-5 border rounded">
          <h3
            class="title-report"
          >
            Estamos cuidando de tudo!
          </h3>
          <p class="pt-3">
            Estamos ajustando a tela de estatística para trazer uma experiência ainda melhor. Mas você pode acessar todas as informações necessárias pela <router-link to="/relatorios">tela de relatórios</router-link>. 
          </p>
        </b-col>
        <b-col v-if="userID == 14333" cols="12">
          <h3 class="title-report" style="margin-bottom: 20px">
            {{ $t('views.seller.sales.text_1421') }}
          </h3>
        </b-col>

        <div v-if="userID == 14333" class="grid-report">
          <template v-for="report in earnReport">
            <div :key="report.title">
              <report-item
                :payload="report"
                :loading="loading_reports"
                :error="errorReport"
                :key="report.name"
              />
            </div>
          </template>
        </div>
      </div>
      <!-- Invoices -->
      <div v-if="userID == 14333 && buttonReportStatus === 'black'" data-anima="left" class="mb-3">
        <b-col cols="12">
          <h3
            class="title-report title-report-margin-top"
            style="margin-bottom: 20px"
          >
            {{ $t('views.seller.sales.text_1337') }}
          </h3>
        </b-col>
        <div class="grid-report">
          <template v-for="report in invoiceReport">
            <div :key="report.title">
              <report-item
                :payload="report"
                :loading="loading_reports"
                :error="errorReport"
                :key="report.name"
              />
            </div>
          </template>
        </div>
      </div>

      <!-- Table Header -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="d-none d-md-block">
          {{ $t('views.seller.sales.text_1338') }}
        </b-col>

        <b-col cols="2" md="2" class="">
          {{ $t('views.seller.sales.text_1422') }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ $t('views.seller.sales.text_1423') }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block"
          >{{ $t('views.seller.sales.text_1339') }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ $t('views.seller.sales.text_1340') }}
        </b-col>

        <b-col cols="1" md="2" class="">
          {{ $t('views.seller.sales.text_1341') }}
        </b-col>

        <b-col cols="1" class="">
          {{ $t('views.seller.sales.text_1424') }}
        </b-col>
      </b-row>

      <!-- Table Body -->
      <template v-if="!loading && sales.length > 0">
        <b-row
          class="Table-body"
          v-for="item in sales"
          :key="item.id"
          :class="getStatus(item).class"
        >
          <!-- Id -->
          <b-col
            cols="1"
            class="d-none d-md-block"
            id="id_venda"
            v-b-tooltip="{
              title: getStatus(item).text,
              placement: 'bottom'
            }"
          >
            {{ item.id }}
          </b-col>
          <!-- Product Name -->
          <template v-if="!item.proposal">
            <b-col cols="2" md="2" class="">
              <div
                class="Table-body-title"
                v-if="item.product"
                v-b-tooltip="{
                  title: setName(item),
                  placement: 'top'
                }"
              >
                {{
                  setName(item) && setName(item).length > maxLength
                    ? setName(item).substr(0, maxLength) + '...'
                    : setName(item)
                }}
                <div class="offer-name">
                  {{ item.offer ? 'Oferta: ' + item.offer.name : '' }}
                </div>
              </div>
              <div
                class="Table-body-title"
                v-else
                v-b-tooltip="{
                  title: setName(item),
                  placement: 'top'
                }"
              >
                {{
                  setName(item) && setName(item).length > maxLength
                    ? setName(item).substr(0, maxLength) + '...'
                    : setName(item)
                }}
                <div class="offer-name">
                  {{ item.offer ? 'Oferta: ' + item.offer.name : '' }}
                </div>
              </div>
            </b-col>
          </template>
          <template v-if="item.proposal">
            <b-col cols="2" md="2" class="">
              <div
                class="Table-body-title"
                v-b-tooltip="{
                  title: item.proposal.name,
                  placement: 'top'
                }"
              >
                {{
                  item.proposal.name && item.proposal.name.length > maxLength
                    ? item.proposal.name.substr(0, maxLength) + '...'
                    : item.proposal.name
                }}
              </div>
            </b-col>
          </template>
          <!-- Client -->
          <b-col
            v-if="item.client"
            cols="2"
            class="d-none d-md-block dados-cliente"
          >
            <div>
              {{
                item.client.name && item.client.name.length > maxLength
                  ? item.client.name.substr(0, maxLength) + '...'
                  : item.client.name
              }}
            </div>

            <div
              v-if="item.client"
              :id="item.client.email"
              v-b-tooltip="{
                title: item.client.email,
                placement: 'top'
              }"
            >
              {{
                item.client.email && item.client.email.length > 20
                  ? item.client.email.substr(0, 20) + '...'
                  : item.client.email
              }}
            </div>

            <div v-if="/\#/.test(item.client.cellphone)">
              {{ item.client.cellphone }}
            </div>

            <div v-else>
              <a
                :href="formatTel(item.client.cellphone)"
                target="_blank"
                class="whats-cliente"
                ><font-awesome-icon :icon="['fab', 'whatsapp']" />{{
                  item.client.cellphone
                }}</a
              >
            </div>
          </b-col>
          <b-col v-else cols="2" class="d-none d-md-block dados-cliente">
          </b-col>
          <!-- Creation Data -->
          <b-col cols="2" class="d-none d-md-block">
            <span v-if="item.created_at">{{ item.created_at | datetime }}</span>
          </b-col>
          <!-- Payment Data -->
          <b-col cols="2" class="d-none d-md-block">
            <span v-if="item.paid_at">{{ item.paid_at | datetime }}</span>
          </b-col>
          <!-- Method -->
          <b-col cols="1" md="2" class="">
            <span class="flex">
              <font-awesome-icon
                v-if="
                  item.method != 'TWO_CREDIT_CARDS' &&
                  item.local_currency_id == 1
                "
                :icon="getIconMethod(item.method, item.local_currency_id)"
                class="icon-gray"
                :id="`method-icon-${item.id}`"
              />
              <div
                v-else-if="
                  item.method != 'TWO_CREDIT_CARDS' &&
                  item.local_currency_id != 1
                "
                :id="`method-icon-${item.id}`"
                class="Table-icon-international"
              >
                <img src="@/assets/img/icons/international.svg" />
              </div>
              <b-tooltip
                :target="`method-icon-${item.id}`"
                :title="getMethod(item.method, item.local_currency_id)"
                placement="left"
              />
              <!-- Two Credit Cards -->
              <font-awesome-icon
                v-if="item.method == 'TWO_CREDIT_CARDS'"
                icon="credit-card"
                class="icon-gray"
                :id="`method-icon-${item.id}`"
              />
              <font-awesome-icon
                v-if="item.method == 'TWO_CREDIT_CARDS'"
                icon="credit-card"
                class="icon-gray TWO_CREDIT_CARDS"
              />
              <!-- Bump -->
              <font-awesome-icon
                v-if="item.bump_id"
                icon="donate"
                class="icon-gray ml-2"
                :id="`bump-sale-${item.id}`"
              />
              <b-tooltip
                v-if="item.bump_id"
                :target="`bump-sale-${item.id}`"
                :title="`ORDER BUMP ${getBumpSequence(item.bump_id, item.id)}`"
                placement="left"
              />
              <!-- Coupom -->
              <font-awesome-icon
                v-if="item.coupon_id"
                icon="receipt"
                class="icon-gray ml-2"
                :id="`cupom-sale-${item.id}`"
              />
              <b-tooltip
                v-if="item.coupon_id"
                :target="`cupom-sale-${item.id}`"
                :title="$t('views.seller.sales.text_1425')"
                placement="left"
              />
            </span>

            <span v-if="item.balance" class="valor-venda">
              {{ currency.currency_symbol }} {{ formatMoney(+item.liquid_total) }}
            </span>
            <span v-else class="valor-venda">
              {{ currency.currency_symbol }} 0,00
            </span>
          </b-col>
          <!-- View -->
          <b-col cols="1" md="1">
            <div class="Table-body-action" @click="openPanel(item.id)">
              <img src="@/assets/img/icons/eye.svg" />
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="sales.length > 0 && !initExport"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <div
        v-if="(loading && !sales.length) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !sales.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t('views.seller.sales.text_1342') }}</p>
      </b-row>

      <!-- modals -->
      <FiltersModal @filter="filter" />
      <ExportModal :filters="exportFilter" />
      <SalePreview :updateQueryParams="setQueryParams" ref="salePreview" />
    </div>
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import SaleService from '@/services/resources/SaleService'
import SaleTotalService from '@/services/resources/SaleTotalService'
import Paginate from '@/components/shared/Paginate'
import FiltersModal from '@/components/Seller/Sales/modals/filters'
import ExportModal from '@/components/Seller/Sales/modals/export'
import DateRangePicker from 'vue2-daterange-picker'
import Money from '@/mixins/money'
import Utils from "@/mixins/utils";
import moment from 'moment-timezone'
import SalePreview from '@/components/shared/SalePreview'
import _ from 'lodash'
import { textMethod } from '@/helpers.js'
import { iconMethod } from '@/helpers.js'
import ReportItem from '@/components/Seller/Sales/ReportItem'
import SelectCurrency from '@/components/SelectCurrency.vue'
import { EventBus } from '@/main.js' 
import { DateUtil } from '@/utils/DateUtil.js'

const service = SaleService.build()
const totalService = SaleTotalService.build()

export default {
  name: 'ListagemVendas',
  mixins: [Utils,Money],
  components: {
    Paginate,
    FiltersModal,
    ExportModal,
    DateRangePicker,
    SalePreview,
    ReportItem,
    SelectCurrency
  },
  data() {
    return {
      earnReport: 4,
      invoiceReport: 6,
      errorReport: false,
      buttonReportStatus: 'white',
      exportFilter: [],
      initExport: false,
      sales: [],
      sale_id: -1,
      search: null,
      loading: true,
      loading_reports: true,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10
      },
      filters: {},
      type_date: 'created_at',
      types_list: [
        { value: 'created_at', text: this.$t('views.seller.sales.text_1426') },
        { value: 'paid_at', text: this.$t('views.seller.sales.text_1427') }
      ],
      localeDate: {
        direction: 'ltr',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        firstDay: 0
      },
      dateRange: {
        startDate: moment(),
        endDate: moment()
      },
      columns: [
        { key: 'id', label: 'Fatura' },
        { key: 'product', label: 'Produto' },
        { key: 'client', label: 'Cliente' },
        { key: 'created_at', label: 'Data de criação' },
        { key: 'paid_at', label: 'Data de Pagamento' },
        { key: 'metas', label: 'Metas' },
        { key: 'balance', label: 'Valor líquido' },
        { key: '_visualize', label: 'Visualizar' }
      ],
      client: { width: 0 },
      currency: {
        id: 1,
        name: 'Real Brasileiro',
        currency: 'BRL',
        currency_symbol: 'R$',
        created_at: '2022-06-27T20:11:50.000000Z',
        updated_at: '2022-06-27T20:11:50.000000Z',
        is_base_currency: 1
      },
      interAccess: false,
      validCoin: true
    }
  },
  computed: {
    active() {
      return this.$route.name
    },
    maxLength() {
      return this.$store.getters['sidebarState'] ? 20 : 27
    },
    dateRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)

      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)

      let lastWeek = new Date()
      lastWeek.setDate(today.getDate() - 7)
      lastWeek.setHours(0, 0, 0, 0)

      return {
        [this.$t('locale_date.today')]: [today, today],
        [this.$t('locale_date.yesterday')]: [yesterday, yesterday],
        [this.$t('locale_date.last_week')]: [lastWeek, today],
        [this.$t('locale_date.current_month')]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30)
        ],
        [this.$t('locale_date.current_year')]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31)
        ],
        [this.$t('locale_date.last_month')]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0)
        ],
        [this.$t('locale_date.every_period')]: [
          new Date(2020, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate())
        ]
      }
    }
  },

  methods: {
    getBumpSequence(bumpId, saleId) {
      const relatedBumps = this.sales
        .filter((sale) => sale.bump_id === bumpId)
        .sort((a, b) => a.id - b.id);

        relatedBumps.findIndex((sale) => sale.id === saleId);

        return (
          relatedBumps.findIndex((sale) => sale.id === saleId) + 1
        );  
    },
    setName(item) {
      return item.product ? item.product.name : item.invoices[0].product.name
    },
    updateStatusAndFetchReports() {
      if (this.buttonReportStatus === 'white') {
        this.buttonReportStatus = 'black'
        this.fetchReports(this.search)
      } else if (this.buttonReportStatus === 'black') {
        this.buttonReportStatus = 'white'
      }
    },
    fetchReports(search = null, exportData = false, dados_local = null) {
      this.loading_reports = true

      let data = {}
      if (dados_local) {
        data = dados_local
      } else {
        data = {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          type: this.type_date,
          status: ['paid', 'waiting_payment']
        }
      }

      if (search != null && typeof search == 'string' && search.length > 0) {
        data.search = search
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        const [startDate, endDate] = this.filterDateRangeAsISOString(this.dateRange.startDate, this.dateRange.endDate)
        data.date_start = startDate
        data.date_end = endDate
      }

      Object.keys(this.filters).forEach(key => {
        ;(this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      })
      data = Object.assign(data, this.filters)

      this.exportFilter = data

      service
        .search({ ...data, url: '/report' })
        .then(response => {
          const typeEarns = [
            {
              name: this.$t('type_earns.total_sales'),
              slug_income: 'total_sales',
              icon: 'moneyReport',
              icon_color: 'blue',
              coin: this.currency.currency_symbol + ' '
            },
            {
              name: this.$t('type_earns.total_balance'),
              slug_income: 'total_balance',
              icon: 'earnUp',
              icon_color: 'green',
              coin: this.currency.currency_symbol + ' '
            },
            {
              name: this.$t('type_earns.total_refund'),
              slug_income: 'total_refund',
              icon: 'earnDown',
              icon_color: 'red',
              coin: this.currency.currency_symbol + ' '
            },
            {
              name: this.$t('type_earns.chargedback'),
              slug_income: 'total_chargedback',
              icon: 'cardReport',
              icon_color: 'grey',
              coin: this.currency.currency_symbol + ' '
            }
          ]
          const typeInvoices = [
            {
              name: this.$t('type_invoices.total'),
              slug_invoice: 'total',
              slug_percent: false,
              icon: 'documentReport',
              icon_color: 'blue'
            },
            {
              name: this.$t('type_invoices.paid'),
              slug_invoice: 'paid',
              slug_percent: 'paid',
              icon: 'checkReport',
              icon_color: 'green'
            },
            {
              name: this.$t('type_invoices.open'),
              slug_invoice: 'open',
              slug_percent: 'open',
              icon: 'lessReport',
              icon_color: 'yellow'
            },
            {
              name: this.$t('type_invoices.unpaid'),
              slug_invoice: 'unpaid',
              slug_percent: 'unpaid',
              icon: 'lessReport',
              icon_color: 'yellow'
            },
            {
              name: this.$t('type_invoices.refunded'),
              slug_invoice: 'refunded',
              slug_percent: 'refunded',
              icon: 'earnDown',
              icon_color: 'red'
            },
            {
              name: this.$t('type_invoices.chargedback'),
              slug_invoice: 'chargedback',
              slug_percent: 'chargedback',
              icon: 'cardReport',
              icon_color: 'grey'
            }
          ]
          let earns = []
          let invoices = []
          typeEarns.map(type => {
            earns.push({
              ...type,
              value: response.incomes[type.slug_income]
                ? response.incomes[type.slug_income]
                : 0
            })
          })
          typeInvoices.map(type => {
            invoices.push({
              ...type,
              value: response.invoices.count[type.slug_invoice],
              percent: type.slug_percent
                ? response.invoices.percent[type.slug_invoice]
                : false
            })
          })
          this.earnReport = earns
          this.invoiceReport = invoices
          this.errorReport = false
        })
        .catch(err => {
          console.error(err)
          this.errorReport = true
        })
        .finally(() => {
          this.loading_reports = false
        })
    },
    getIconMethod(method, currency_id) {
      return iconMethod(method, currency_id)
    },
    getMethod(method, currency_id) {
      return textMethod(method, currency_id)
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf('+55') > -1) {
          var tell = tel.replace(/[() -]/g, '')
          if (tell[0] === '+') {
            tell = tell.substring(1)
          }
          return `https://api.whatsapp.com/send?phone=${tell}`
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ''
          )}`
        }
      }
    },
    getStatus(item) {
      let r = {}
      if (item.status) {
        switch (item.status.toLowerCase()) {
          case 'processing':
            r = {
              text: this.$t('status_sales.processing'),
              class: 'is-light-blue'
            }
            break
          case 'authorized':
            r = {
              text: this.$t('status_sales.authorized'),
              class: 'is-light-blue'
            }
            break
          case 'paid':
            r = { text: this.$t('status_sales.paid'), class: 'is-green' }
            break
          case 'refunded':
            r = { text: this.$t('status_sales.refunded'), class: 'is-red' }
            break
          case 'waiting_payment':
            r = {
              text: this.$t('status_sales.waiting_payment'),
              class: 'is-warning'
            }
            break
          case 'pending_approbation':
            r = {
              text: this.this.$t('status_sales.pending_approbation'),
              class: 'is-light-blue'
            }
            break
          case 'pending_refund':
          case 'refund_pending':
            r = {
              text: this.$t('status_sales.refund_pending'),
              class: 'is-warning'
            }
            break
          case 'refused':
            r = { text: this.$t('status_sales.refused'), class: 'is-red' }
            break
          case 'chargedback':
            r = {
              text: this.$t('status_sales.chargedback'),
              class: 'is-warning'
            }
            break
          case 'delayed':
            r = { text: this.$t('status_sales.delayed'), class: 'is-warning' }
            break
          default:
            r = { text: item.status, class: 'is-black' }
            break
        }
        return r
      } else return { text: item.status, class: 'is-black' }
    },
    handleResize() {
      this.client.width = window.innerWidth
    },
    changedType() {
      // chamando dessa forma para nao
      // passar o event como parametro
      this.fetchSales(this.search)
    },
    debounce: _.debounce(function () {
      this.fetchSales(this.search)
    }, 500),
    getCoSellerAmount(item) {
      if (item.product.co_sellers) {
        for (let i = 0; i < item.product.co_sellers.length; i++) {
          if (item.product.co_sellers[i].user_id == this.userID) {
            const percentage = item.product.co_sellers[i].percentage
            return ((item.amount - item.fee) * percentage) / 100
          }
        }
      }

      return 0
    },
    openPanel(sale_id) {
      this.sale_id = sale_id
      this.$router.push({ query: { s_id: this.sale_id } }).catch(() => {})

      setTimeout(() => {
        this.$refs.salePreview.openPanel(this.sale_id)
        this.loading = false
      })
    },
    openFilters() {
      this.$bvModal.show('sales-filters')
    },
    exportData() {
      this.$bvModal.show('sales-export')
    },
    toPage(page) {
      this.pagination.currentPage = page
      this.fetchSales(this.search)
    },
    perPage(qtd) {
      this.pagination.currentPage = 1
      this.pagination.perPage = qtd

      this.fetchSales(this.search)
    },

    filter(filters) {
      this.filters = filters
      this.pagination.currentPage = 1
      this.fetchSales()
    },

    fetchSales(search = null, exportData = false, dados_local = null) {
      if (typeof this.$route.query.s_id == 'undefined') {
        this.$router
          .replace({
            query: {
              start_date: DateUtil.format(this.dateRange.startDate, 'MM-dd-yyyy'),
              end_date: DateUtil.format(this.dateRange.endDate, 'MM-dd-yyyy'),
              ...this.filters
            }
          })
          .catch(() => {})
      }

      this.loading = true
      this.pagination.totalPages = 1

      if (this.buttonReportStatus === 'black') {
        this.fetchReports(search)
      }

      let data = {}
      if (dados_local) {
        data = {
          date_start: dados_local.date_start,
          page: dados_local.page,
          per_page: dados_local.per_page,
          status: dados_local.status,
          type: dados_local.type
        }
      } else {
        data = {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          type: this.type_date,
          status: ['paid', 'waiting_payment']
        }
      }

      if (!exportData) {
        // reset
        this.pagination.totalPages = 1
        this.sales = []
        this.total = 0
        this.itens = 0
      }

      if (search != null && typeof search == 'string' && search.length > 0) {
        data.search = search
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        console.log(this.dateRange.endDate)
        const [startDate, endDate] = this.filterDateRangeAsISOString(this.dateRange.startDate, this.dateRange.endDate)
        data.date_start = startDate
        data.date_end = endDate
        console.log(endDate)
      }

      Object.keys(this.filters).forEach(
        key =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      )
      data = Object.assign(data, this.filters)

      this.exportFilter = data

      service
        .search(data)
        .then(response => {
          // salvando pesquisa no localStorage
          this.saveSearch(data)
          this.sales = response.data.data
          this.pagination.totalPages = response.data.last_page
          this.itens = response.data.total
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading = false
        })
      this.fetchTotal(data)
    },
    getMetas(metas) {
      var result = []
      if (metas.length) {
        metas.forEach(element => {
          result.push(element.meta_key + '=' + element.meta_value)
        })
      }

      return result.join('&')
    },
    searchByFilter(dados_filter) {
      this.type_date = dados_filter.type
      this.dateRange.startDate = this.decodeDateFilter(dados_filter.date_start)
      this.dateRange.endDate = this.decodeDateFilter(dados_filter.date_end)
      this.fetchSales(null, false, dados_filter)
    },
    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case this.$t('locale_date.today_lowercase'): {
          return moment()
        }
        // ontem
        case this.$t('locale_date.yesterday_lowercase'): {
          return moment().subtract(1, 'days')
        }
        // uma semana atras
        case this.$t('locale_date.one_week'): {
          return moment().subtract(7, 'days')
        }
        // primeiro dia do mes atual
        case this.$t('locale_date.first_day_current_month'): {
          return moment().startOf('month')
        }
        // ultimo dia do mes atual
        case this.$t('locale_date.last_day_current_month'): {
          return moment().endOf('month')
        }
        // primeiro dia do ANO atual
        case this.$t('locale_date.first_day_year'): {
          return moment().startOf('year')
        }
        // ultimo dia do ANO atual
        case this.$t('locale_date.last_day_year'): {
          return moment().endOf('year')
        }

        // primeiro dia do mes passado
        case this.$t('locale_date.first_day_last_month'): {
          return moment().startOf('month').subtract(1, 'month')
        }
        // ultimo dia do mes passado
        case this.$t('locale_date.last_day_last_month'):
          return moment().endOf('month').subtract(1, 'month').add(1, 'days')
        default: {
          return date
        }
      }
    },
    saveSearch(dados_pesquisa) {
      window.localStorage.sales_filters = {}
      dados_pesquisa.date_start = this.formatDateFilter(
        dados_pesquisa.date_start
      )
      dados_pesquisa.date_end = this.formatDateFilter(dados_pesquisa.date_end)
      window.localStorage.sales_filters = JSON.stringify(dados_pesquisa)
    },
    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format('YYYY-MM-DD'): {
          return this.$t('locale_date.today_lowercase')
        }
        // ontem
        case moment().subtract(1, 'days').format('YYYY-MM-DD'): {
          return this.$t('locale_date.yesterday_lowercase')
        }
        // uma semana atras
        case moment().subtract(7, 'days').format('YYYY-MM-DD'): {
          return this.$t('locale_date.one_week')
        }
        // primeiro dia do mes atual
        case moment().startOf('month').format('YYYY-MM-DD'): {
          return this.$t('locale_date.first_day_current_month')
        }
        // ultimo dia do mes atual
        case moment().endOf('month').format('YYYY-MM-DD'): {
          return this.$t('locale_date.last_day_current_month')
        }
        // primeiro dia do ANO atual
        case moment().startOf('year').format('YYYY-MM-DD'): {
          return this.$t('locale_date.first_day_year')
        }
        // ultimo dia do ANO atual
        case moment().endOf('year').format('YYYY-MM-DD'): {
          return this.$t('locale_date.last_day_year')
        }

        // primeiro dia do mes passado
        case moment()
          .startOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD'): {
          return this.$t('locale_date.first_day_last_month')
        }
        // ultimo dia do mes passado
        case moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD'):
        case moment()
          .endOf('month')
          .subtract(1, 'month') 
          .add(1, 'days')
          .format('YYYY-MM-DD'): {
          return this.$t('locale_date.last_day_last_month')
        }
        default: {
          return date
        }
      }
    },
    updateCoin(coin) {
      if (this.validCoin) {
        this.currency = coin
        this.pagination.currentPage = 1
        this.pagination.perPage = 10
        this.fetchSales()
        this.validCoin = false
      } else this.validCoin = true
    },
    setQueryParams(isNotToRefresh) {
      this.dateRange.startDate =
        this.$route.query.start_date ||
        this.dateRange.startDate ||
        moment().format('MM-DD-YYYY')
      this.dateRange.endDate =
        this.$route.query.end_date ||
        this.dateRange.endDate ||
        moment().format('MM-DD-YYYY')
      this.filters.status =
        this.$route.query.status || this.filters.status || ''
      this.filters.method =
        this.$route.query.method || this.filters.method || ''
      this.filters.meta_key =
        this.$route.query.meta_key || this.filters.meta_key || ''
      this.filters.meta_value =
        this.$route.query.meta_value || this.filters.meta_value || ''
      this.filters.products =
        this.$route.query.products || this.filters.products || ''
      this.filters.contracts =
        this.$route.query.contracts || this.filters.contracts || ''
      this.filters.affiliates =
        this.$route.query.affiliates || this.filters.affiliates || ''
      if (isNotToRefresh) {
        this.$router
          .replace({
            query: {
              start_date: moment(this.dateRange.startDate).format('MM-DD-YYYY'),
              end_date: moment(this.dateRange.endDate).format('MM-DD-YYYY'),
              ...this.filters
            }
          })
          .catch(() => {})
      } else {
        this.fetchSales()
      }
    },
    async fetchTotal(filters = {}) {
      await totalService.search(filters).then(res => {
        this.total = res.total
      })
    },
    filterDateRangeAsISOString(startDate, endDate) {
      const startDay = DateUtil.format(startDate, 'yyyy-MM-ddT00:00:00')
      const endDay = DateUtil.format(endDate, 'yyyy-MM-ddT23:59:59')
      const toISOString = date => DateUtil.toDate(date).toISOString()
      return [toISOString(startDay), toISOString(endDay)]
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    let userInterIds = process.env.USER_INTER.substring(
      1,
      process.env.USER_INTER.length - 1
    ).split(',')
    this.interAccess = userInterIds.some(
      userId => parseInt(userId) === this.userID
    )
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },

  mounted() {
    if (JSON.parse(localStorage.user).country)
      if (JSON.parse(localStorage.user).country.base_currency) {
        this.currency = JSON.parse(localStorage.user).country.base_currency
      }

    this.loading = true
    // filtros salvos
    if (window.localStorage.sales_filters) {
      let filters = JSON.parse(window.localStorage.sales_filters)
      filters.date_start =
        this.$route.query.start_date || moment().format('MM-DD-YYYY')
      filters.date_end =
        this.$route.query.end_date || moment().format('MM-DD-YYYY')
      this.searchByFilter(filters)
    } else {
      this.dateRange.startDate =
        this.$route.query.start_date || moment().format('MM-DD-YYYY')
      this.dateRange.endDate =
        this.$route.query.end_date || moment().format('MM-DD-YYYY')
      this.fetchSales()
    }

    // abrindo venda unica
    if (typeof this.$route.query.s_id != 'undefined') {
      this.sale_id = this.$route.query.s_id
      setTimeout(() => {
        this.$refs.salePreview.openPanel(this.sale_id)
        this.loading = false
      }, 1000)
    }
    
    EventBus.$on('relist-sales', () => {
      this.fetchSales()
    })
  }
}
</script>

<style lang="scss" scoped>
.Table-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Table-header div {
  color: #091e3e;
  line-height: 21px;
  font-weight: 600px;
}
.total-vendas.info {
  font-size: 12px;
  font-weight: normal;
  color: #091e3e;
  line-height: 18px;
}

.total-vendas {
  font-size: 14px;
  font-weight: bold;
  color: #091e3e;
  line-height: 21px;
}

.total-vendas span {
  font-size: 18px;
  font-weight: 600;
  color: #091e3e;
  margin-left: 5px;
  line-height: 27px;
}
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: max-content;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #f5f5f5;
  padding: 16px;
  outline: none;
}

.btn-table span {
  color: #091e3e;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 8px;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 190px;
  font-size: 14px !important;
  color: #81858e !important;
  text-align: center;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  border-radius: 5px;
  // padding: 20px 30px;
  padding-left: 145px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  // text-align: end;
  padding-right: 0px !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}
.input-busca::placeholder {
  color: #091e3e;
}
.search {
  position: absolute;
  left: 157px;
  top: 23px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: #002363;
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: '';
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
.button {
  width: 179px;
  height: 55px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #333;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button--black {
  background: rgba(0, 33, 99, 0.1);
  color: #091e3e;
  margin-right: 20px;
}

.button--white {
  margin-right: 20px;
}

.button:hover {
  transform: scale(1);
}
.inverted--black {
  filter: invert(100);
}
.title-report {
  color: #333;
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}
</style>
