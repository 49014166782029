<template>
  <div class="loading">
    <img src="@/assets/img/loading.svg" />
  </div>
</template>

<script>
export default {
  name: "admin-login",
  data() {
    return {
      fullUrl: "",
    };
  },
  methods: {
    async login(user_id) {
      let data = {
        user_id: user_id,
      };
      
      await this.$store.dispatch("authRequestWhitUser", data);
    },
    
    removeAllCookies() {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }
    }
  },
  async created() {
    this.removeAllCookies()

    let user_id = Buffer.from(this.$route.params.userid, 'base64').toString('utf-8');
    
    this.fullUrl = window.location.href
    
    if (await this.$store.getters.isAuthenticated) {
      await this.$store.dispatch("logoutRequest")
      .catch(() => {})
      .finally(() => {
        window.location.href = this.fullUrl;
      });	
    }

    await this.login(user_id);
  },
};
</script>

<style lang="scss">
.loading {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  img {
    width: 100px;
  }
}
</style>
