export default function (code) {
  let r = ''
  switch (code) {
    case "0001": {
      r = "Cupom inválido.";
      break;
    }
    case "BANK": {
      r = "O emissor do cartão recusou o pagamento.";
      break;
    }
    case "BLACKLIST_PURCHASE": {
      r = "O emissor do cartão bloqueou o pagamento.";
      break;
    }
    case "INVALID_CVV": {
      r = "O código de segurança do cartão está incorreto.";
      break;
    }
    case "INVALID_CLIENT_DATA": {
      r = "Os dados do pagador do cartão estão incorretos.";
      break;
    }
    case "DUPLICATE_PURCHASE": {
      r = "Compra em duplicidade";
      break;
    }
    case "PRODUCT_OUT_OF_STOCK": {
      r = "Produto sem estoque.";
      break;
    }
    case "CREDIT_CARD_OPERATOR": {
      r = "O emissor do cartão recusou o pagamento.";
      break;
    }
    case "INVALID_DATA": {
      r = "Os dados do cartão foram inseridos incorretamente";
      break;
    }
    case "INVALID_CREDIT_CARD": {
      r = "O cartão está desabilitado, bloqueado ou vencido.";
      break;
    }
    case "INSUFFICIENT_FUNDS": {
      r = "O cartão não possui limite para essa compra.";
      break;
    }
    case "INVALID_PAYMENT_TYPE": {
      r = "O método para o pagamento é inválido.";
      break;
    }
    case "INVALID_INSTALLMENTS": {
      r = "O número ou valor das parcelas não foi autorizado.";
      break;
    }
    case "CURRENCY_NOT_SUPPORTED": {
      r = "Moeda não suportada.";
      break;
    }
    case "SUSPECTED_FRAUD": {
      r = "Suspeita de Fraude.";
      break;
    }
    case "GENERIC": {
      r = "Um erro inesperado ocorreu no processo de compra.";
      break;
    }
    // assinatura
    case '0404': {
      r = 'Nenhuma assinatura foi efetuada com este documento.';
      break;
    }
    case '1404': {
      r = 'Esse produto não é uma assinatura.';
      break;
    }
    case '1405': {
      r = 'Não há nenhuma assinatura vinculada a este documento.';
      break;
    }
    default: {
      r = `Um erro inesperado ocorreu no processo de compra. - Código: ${code}`;
      break;
    }
  }
  return r;
}