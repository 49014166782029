<template>
  <div class="container">
    <BaseHeader :title="$t('views.seller.links.text_1125')">
      <BaseButton
        variant="primary"
        v-b-modal.links-new
        @click="dataEdit = null"
        class="btn-adicionar"
        :disabled="!product_id"
        >
        {{ $t("views.seller.links.text_1129") }}
      </BaseButton>
    </BaseHeader>
    <b-tabs
      class="mt-5 mb-5"
      content-class="mt-3"
      nav-class="TabHeader"
      active-nav-item-class="TabHeader--active"
      @activate-tab="tabSelect"
    >
      <b-tab
        :title="$t('views.seller.links.text_1607')"
        :active="!comingAffiliate"
      >
        <b-container class="mt-1 p-5 wrapper container-item">
          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <b-row v-if="!loading">
            <b-col>
              <h6 class="mb-3">{{ $t("views.seller.links.text_1126") }}</h6>
              <b-form-group label="">
                <multiselect
                  v-model="product_id"
                  :placeholder="$t('views.seller.links.text_1127')"
                  label="name"
                  :name="`link-${productsSeller.id}`"
                  track-by="id"
                  :options="filterBy(productsSeller)"
                  :multiple="false"
                  :taggable="false"
                  ><span slot="noResult">{{
                    $t("views.seller.links.text_1128")
                  }}</span>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                      <span class="option__desc" v-if="props.option.status !== 'APPROVED'"> (Produto indisponível - em análise)</span>
                    </div>
                  </template>
                </multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="product_id">
            <div class="d-flex justify-content-center" v-if="loading">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <b-row class="mt-4">
              <b-col cols="12" md="6">
                <b-form-group :label="$t('views.seller.links.text_1130')">
                  <a
                    v-if="salesPage"
                    :href="salesPage"
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ cutText(salesPage) }}</a
                  >
                  <p v-if="!salesPage" class="p-editado">
                    {{ $t("views.seller.links.text_1131") }}
                  </p>
                  <span
                    @click="copyAddress(salesPage)"
                    class="btn p-2"
                    v-if="!load"
                  >
                    <img
                      src="@/assets/img/icons/copy.svg"
                      class="copy-link"
                      v-if="salesPage"
                    />
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group :label="$t('views.seller.links.text_1608')">
                  <a
                    :href="baseURL + '/' + product.id"
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ cutText(baseURL + "/" + product.id) }}</a
                  >
                  <span
                    v-if="!load"
                    @click="copyAddress(baseURL + '/' + product.id)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link"
                  /></span>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group :label="$t('views.seller.links.text_1132')">
                  <a
                    data-anima="bottom"
                    v-if="link_afiliado == '---'"
                    href="#"
                    class="link"
                    @click.prevent="affiliateLink"
                    :class="{ carregando: load }"
                    >{{ $t("views.seller.links.text_1133") }}</a
                  >
                  <a
                    data-anima="bottom"
                    v-if="link_afiliado != '---'"
                    :href="link_afiliado"
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ cutText(link_afiliado, 100) }}</a
                  >
                  <span
                    @click="copyAddress(link_afiliado)"
                    class="btn p-2"
                    v-if="!load && link_afiliado != '---'"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-2 outros">
              <b-col>
                <h6 class="outros-links">
                  {{ $t("views.seller.links.text_1609") }}
                </h6>
              </b-col>
            </b-row>
            <b-row class="mb-4" v-if="links.length >= 1 && !isMobile">
              <b-col cols="12" md="6" class="sub-titulo">
                {{ $t("views.seller.links.text_1610") }}
              </b-col>
              <b-col cols="12" md="6" class="sub-titulo"> URL </b-col>
            </b-row>
            <template v-for="item in offers">
              <b-row :key="item.id" class="link-extra">
                <b-col cols="12" md="6" class="titulo-link" :title="item.name">
                  {{ cutText(item.name, 65) }}
                </b-col>
                <b-col cols="12" md="6" class="text-left text-md-right">
                  <a
                    :href="
                      baseURL + '/' + item.product_id + '/offer/' + item.hash
                    "
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{
                      cutText(
                        baseURL + "/" + item.product_id + "/offer/" + item.hash, 60
                      )
                    }}</a
                  >
                  <span
                    v-if="!load"
                    @click="
                      copyAddress(
                        baseURL + '/' + item.product_id + '/offer/' + item.hash
                      )
                    "
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-col>
              </b-row>
            </template>
            <b-row class="my-2 outros">
              <b-col>
                <h6 class="outros-links">
                  {{ $t("views.seller.links.text_1611") }}
                </h6>
              </b-col>
            </b-row>
            <b-row class="mb-4" v-if="links.length >= 1 && !isMobile">
              <b-col cols="12" md="6" class="sub-titulo">
                {{ $t("views.seller.links.text_1612") }}
              </b-col>
              <b-col cols="12" md="6" class="sub-titulo"> URL </b-col>
            </b-row>
            <template v-for="item in links">
              <b-row
                v-if="item.name != 'MAIN'"
                :key="item.id"
                class="link-extra"
              >
                <b-col cols="12" md="6" class="titulo-link">
                  {{ cutText(item.name, 60) }}
                  <img
                    v-show="item.is_alert && item.alert_type === 'warning'"
                    src="@/assets/img/icons/notify_warning.svg"
                    :title="item.alert_text"
                    class="order-bump-alert"
                  />
                  <img
                    v-show="item.is_alert && item.alert_type === 'danger'"
                    src="@/assets/img/icons/info-prod-reprovado.svg"
                    :title="item.alert_text"
                    class="order-bump-alert"
                  />
                </b-col>
                <b-col cols="12" md="6" class="text-left text-md-right">
                  <a
                    :href="baseURL + '/redirect/' + item.id"
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ cutText(baseURL + "/redirect/" + item.id, 60)}}</a
                  >
                  <span
                    v-if="!load && !item.is_alert"
                    @click="copyAddress(baseURL + '/redirect/' + item.id)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>

                  <span
                    v-if="!load && item.is_alert"
                    @click="
                      copyAddressWithWarning(
                        baseURL + '/redirect/' + item.id,
                        item.alert_text
                      )
                    "
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>

                  <span
                    v-show="!load && item.is_bump === 1 && item.allow_edit"
                    @click="editItem(item)"
                    class="btn p-2"
                  >
                    <img
                      src="@/assets/img/icons/editar.svg"
                      class="copy-link"
                    />
                  </span>
                  <span
                    @click="removeLink(item.id, false)"
                    class="btn p-2"
                    v-if="!load"
                  >
                    <img
                      src="@/assets/img/icons/lixeira.svg"
                      class="copy-link"
                    />
                  </span>
                </b-col>
              </b-row>
            </template>
          </template>
        </b-container>
      </b-tab>

      <b-tab title="Afiliado" :active="comingAffiliate">
        <b-container class="mt-1 p-5 wrapper container-item">
          <b-row>
            <b-col>
              <h6 class="mb-3">{{ $t("views.seller.links.text_1135") }}</h6>

              <b-form-group label="">
                <multiselect
                  v-model="product_id_afiliado"
                  :placeholder="$t('views.seller.links.text_1127')"
                  label="name"
                  :name="`link-${productsAffiliate.id}`"
                  track-by="id"
                  :options="productsAffiliate"
                  :multiple="false"
                  :taggable="false"
                  ><span slot="noResult">{{
                    $t("views.seller.links.text_1128")
                  }}</span></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="product_id_afiliado">
            <b-row class="mt-4">
              <b-col cols="12">
                <b-form-group :label="$t('views.seller.links.text_1130')">
                  <a
                    v-if="salesPage"
                    :href="salesPage ? salesPage + '/?a_id=' + userID : ''"
                    class="link"
                    target="_blank"
                    :class="{ carregando: load }"
                  >
                    {{
                      cutText(salesPage ? salesPage + "/?a_id=" + userID : "", 100)
                    }}
                  </a>
                  <p v-if="!salesPage" class="p-editado">
                    {{ $t("views.seller.links.text_1131") }}
                  </p>
                  <span
                    v-if="!load && salesPage"
                    @click="copyAddress(salesPage + '/?a_id=' + userID)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group :label="$t('views.seller.links.text_1608')">
                  <a
                    :href="baseURL + '/' + product.id + '/?a_id=' + userID"
                    class="link"
                    target="_blank"
                    :class="{ carregando: load }"
                  >
                    {{
                      cutText(baseURL + "/" + product.id + "/?a_id=" + userID, 100)
                    }}
                  </a>
                  <span
                    v-if="!load"
                    @click="
                      copyAddress(
                        baseURL + '/' + product.id + '/?a_id=' + userID
                      )
                    "
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-2 outros">
              <b-col>
                <h6 class="outros-links">
                  {{ $t("views.seller.links.text_1609") }}
                </h6>
              </b-col>
            </b-row>
            <template v-for="item in offers">
              <b-row :key="item.id" class="link-extra">
                <b-col cols="12" md="6" class="titulo-link" :title="item.name">
                  {{ cutText(item.name, 65) }}
                </b-col>
                <b-col cols="12" md="6" class="text-left text-md-right">
                  <a
                    :href="
                      baseURL + '/' + item.product_id + '/offer/' + item.hash + '/?a_id=' + userID
                    "
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{
                      cutText(
                        baseURL + "/" + item.product_id + "/offer/" + item.hash + '/?a_id=' + userID, 60
                      )
                    }}</a
                  >
                  <span
                    v-if="!load"
                    @click="
                      copyAddress(
                        baseURL + '/' + item.product_id + '/offer/' + item.hash + '/?a_id=' + userID
                      )
                    "
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-col>
              </b-row>
            </template>
            <b-row class="my-3 outros">
              <b-col>
                <h6 class="outros-links">
                  {{ $t("views.seller.links.text_1611") }}
                </h6>
              </b-col>
            </b-row>
            <template v-for="item in links">
              <b-row v-if="item.name != 'MAIN'" :key="item.id">
                <b-col cols="12" md="6" class="titulo-link" :title="item.name">
                  {{ cutText(item.name, 60) }}
                </b-col>
                <b-col cols="12" md="6"  class="outro-item text-left text-md-right">
                  <a
                    :href="
                      baseURL + '/redirect/' + item.id + '/?a_id=' + userID
                    "
                    class="link"
                    target="_blank"
                    :class="{ carregando: load }"
                  >
                    {{
                      cutText(
                        baseURL + "/redirect/" + item.id + "/?a_id=" + userID, 60
                      )
                    }}
                  </a>
                  <span
                    v-if="!load"
                    @click="
                      copyAddress(
                        baseURL + '/redirect/' + item.id + '/?a_id=' + userID
                      )
                    "
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-col>
              </b-row>
            </template>
            <!-- <b-row v-if="links.length == 1" class="container">
              <p class="link">Nenhum outro link foi encontrado </p>
            </b-row> -->
          </template>
        </b-container>
      </b-tab>
    </b-tabs>

    <NovoLink
      :checkouts="checkouts"
      :isPhysical="isPhysical"
      :product_id="product.id"
      :product_type="product.type"
      :product_name="product.name"
      :product_installments="validItem(product)"
      :productsS="productsSeller"
      :productsA="productsAffiliate"
      :type="comingAffiliate"
      :_cupom="cupom"
      :has_orderbump="has_orderbump"
      :all_edit="dataEdit"
      @close="fetchLinks"
    />
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import Vue from "vue";
import Utils from "@/mixins/utils";
import ProductService from "@/services/resources/ProductService";
import CheckoutService from "@/services/resources/CheckoutService";
import AffiliateService from "@/services/resources/AffiliateService";
import LinkService from "@/services/resources/LinkService";
import NovoLink from "@/components/Seller/Links/modals/new";

const serviceProducts = ProductService.build();
const serviceCheckouts = CheckoutService.build();
const serviceAffiliate = AffiliateService.build();
const serviceLinks = LinkService.build();
import Multiselect from "vue-multiselect";

export default {
  mixins: [Utils],
  components: {
    NovoLink,
    Multiselect,
  },
  data() {
    return {
      dataEdit: null,
      submit: false,
      loading: false,
      comingAffiliate: false,
      has_orderbump: false,
      cupom: null,
      has_cupom: false,
      product_id: null,
      product_id_afiliado: null,
      links: [],
      offers: [],
      products: [],
      product: {
        sales_page: "",
        slug: "",
      },
      productsSeller: [],
      productsAffiliate: [],
      load: true,
      link_afiliado: "---",
      client: {
        width: 0,
      },
      maxLength: 48,
      checkouts: [],
      createLink: false,
    };
  },
  computed: {
    isMobile() {
      if (this.client.width <= 667) this.maxLength = 33;
      return this.client.width <= 768;
    },
    baseURL() {
      return process.env.VUE_SALES_PAGE;
    },
    baseAdmURL() {
      return process.env.VUE_ADM_PAGE;
    },
    salesPage() {
      let r = "";
      for (var i = 0; i < this.links.length; i++) {
        if (this.links[i].url && this.links[i].name == "MAIN") {
          r = this.baseURL + "/redirect/" + this.links[i].id;
        }
      }
      return r;
    },

    isPhysical() {
      return this.product.format === "PHYSICALPRODUCT";
    },
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.maxLength = 33;
      } else this.maxLength = 48;
    },
    product_id() {
      if (!this.has_cupom) {
        this.selectProduct(false);
      } else {
        this.fetchLinks();
        this.fetchOffers();
        this.createLink = true;
        setTimeout(() => {
          this.load = false;
          this.has_cupom = false;
        }, 500);
      }
    },
    product_id_afiliado() {
      this.selectProduct(true);
    },
  },
  methods: {
    filterBy(data) {
      return data.filter((data) => {
        if (
          data.status !== 'APPROVED'
        ) {
          return (data.$isDisabled = true);
        } else {
          delete data.$isDisabled;
          return data;
        }
      });
    },
    validItem(product) {
      // if(product.max_boleto_installments !== undefined && product.max_boleto_installments !== 0 && product.max_boleto_installments !== null || product.format === "PHYSICALPRODUCT"){
      //   return false;
      // }
      return true;
    },
    getParameterByName(name, url) {
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    editItem(item) {
      this.fetchSellerProducts2();
      item.bump_id = this.getParameterByName("b_id", item.url);

      let bumpIds = [];
      let bumpOffers = [];

      const params = new URLSearchParams(new URL(item.url).search);

      params.forEach((value, key) => {
        if (key.startsWith("b_id")) {
            bumpIds.push(parseInt(value));
        }
        if (key.startsWith("b_offer")) {
            bumpOffers.push(value);
        }
      });

      var myArray = this.productsSeller;
      item.product_order_b = [];

      bumpIds.forEach((bump_id, index) => {
        let filter = myArray.filter((x) => x.id === bump_id);
        item.product_order_b.push({
            bump_id: bump_id,
            bump_offer: bumpOffers[index] || null,
            product: filter
        });
      });

      this.dataEdit = item;
      this.$bvModal.show("links-new");
    },
    setProduct(reset, produtor = false) {
      if (!produtor) {
        if (this.$route.query.affiliation_id && !reset) {
          this.product_id_afiliado = {
            id: +this.$route.query.affiliation_id,
            name: this.$route.query.p_name,
          };
        } else if (reset) {
          this.product_id_afiliado = null;
        }
      } else {
        this.product_id = {
          id: +this.$route.query.p_id,
          name: this.$route.query.p_name,
          type: this.$route.query.p_type,
        };

        if (this.has_orderbump) {
          this.$bvModal.show("links-new");
          if (this.product_id.type == "SUBSCRIPTION") {
            this.$bvToast.toast(
              `${this.$t("views.seller.links.text_1613")} ${
                this.product_id.name
              } ${this.$t("views.seller.links.text_1136")}`,
              {
                title: "Link",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        }
      }
    },
    cutText(text, maxLength = null) {
      maxLength = maxLength || this.maxLength;
      if (text) {
        return text.length > maxLength
          ? text.substr(0, maxLength) + "..."
          : text;
      } 
      return text;
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    affiliateLink() {
      if (this.product.id) {
        let data = {
          product_id: this.product.id,
          url: "invite",
        };

        serviceAffiliate
          .search(data)
          .then((response) => {
            this.link_afiliado = `${process.env.VUE_ADM_PAGE}/recrutamento/${response.hash}/?p_id=${this.product.id}`;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    removeLink(id, affiliate) {
      Vue.swal({
        title: this.$t("views.seller.links.text_1138"),
        text: this.$t("views.seller.links.text_1139"),
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("views.seller.links.text_1138"),
        cancelButtonText: this.$t("views.seller.links.text_1614"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            id: id,
          };

          serviceLinks.destroy(data).then(() => {
            if (affiliate) {
              this.selectProduct(true);
            } else this.selectProduct(false);

            this.$bvToast.toast(this.$t("views.seller.links.text_1142"), {
              title: "Link",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
        }
      });
    },

    copyAddress(link) {
      const el = document.createElement("textarea");
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(this.$t("views.seller.links.text_1615"), {
        title: this.$t("views.seller.links.text_1616"),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    copyAddressWithWarning(link, message) {
      const el = document.createElement("textarea");
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(message, {
        title: this.$t("views.seller.links.text_1616"),
        variant: "warning",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    selectProduct(affiliate) {
      this.load = true;
      if (this.product_id) {
        let product = [...this.products].find((item) => {
          if (item.id == this.product_id.id) {
            return item;
          }
        });
        // if (affiliate) this.product = product.;
        // else this.product = product[0];
        this.product = product;
      }
      if (affiliate) {
        if (this.product_id_afiliado) {
          let product = [...this.products].find((item) => {
            if (item.id == this.product_id_afiliado.id) {
              return item;
            }
          });
          this.product = product.product || product;
        }
      }
      this.fetchLinks();
      this.fetchOffers();
      this.verifyAfilliate();
      setTimeout(() => {
        this.load = false;
      }, 500);
    },
    verifyAfilliate() {
      if (this.userID === this.product.seller_id) {
        this.createLink = true;
      } else {
        this.createLink = false;
      }
    },
    tabSelect(newTabIndex) {
      this.product_id = null;
      this.product_id_afiliado = null;
      this.link_afiliado = "---";
      switch (newTabIndex) {
        case 0: {
          this.comingAffiliate = false;
          this.fetchSellerProducts();
          break;
        }
        case 1: {
          this.comingAffiliate = true;
          this.fetchAffiliateProducts();
          break;
        }
      }
    },
    fetchLinks() {
      let data = {
        product_id: this.product.id,
      };

      if (!this.product.id) {
        return;
      }

      serviceLinks.search(data).then((response) => {
        if (Array.isArray(response) && response.length) {
          this.links = response.map((link) => {
            link.is_alert = false;
            link.allow_edit = true;
            link.alert_type = null;
            link.alert_text = null;

            if (
              link.offer_select &&
              link.offer_select.status_offer !== "APPROVED"
            ) {
              link.is_alert = true;
              link.alert_type = "warning";
              link.alert_text = `O link ativará automaticamente após análise da oferta: ${link.offer_select.name}`;

              return link;
            }

            if (link.is_bump) {
              if (link.bump_select && link.bump_select) {
                const product = link.bump_select;

                if (product.is_active === 0) {
                  link.is_alert = true;
                  link.allow_edit = false;
                  link.alert_type = "danger";
                  link.alert_text = `Order bump desativado, produto: ${product.name}`;

                  return link;
                }

                if (
                  product.status !== "APPROVED" ||
                  product.status_product !== "APPROVED"
                ) {
                  link.is_alert = true;
                  link.allow_edit = true;
                  link.alert_type = "warning";
                  link.alert_text = `O link ativará automaticamente após análise do produto: ${product.name}`;

                  return link;
                }
              }

              if (
                link.offer_bump_select &&
                link.offer_bump_select.status_offer !== "APPROVED"
              ) {
                link.is_alert = true;
                link.alert_type = "danger";
                link.allow_edit = false;
                link.alert_text = `A oferta não aprovada! Oferta: ${link.offer_bump_select.name}`;

                return link;
              }
            }

            return link;
          });
        }
      });
    },
    fetchOffers() {
      if (this.product.id) {
        let data = {
          id: this.product.id + "/offers",
        };
        serviceProducts.read(data).then((response) => {
          this.offers = response;
        });
      }
    },
    fetchSellerProducts2() {
      let data = {
        list: true,
      };

      serviceProducts
        .search(data)
        .then((response) => {
          this.productsSeller = [];
          response.forEach((element) => {
            this.productsSeller.push({
              id: element.id,
              name: element.name,
              type: element.type,
              max_boleto_installments: element.max_boleto_installments,
              format: element.format,
              offers: element.offers,
              status: element.status,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchSellerProducts() {
      this.loading = true;
      this.index = null;
      this.product = {
        sales_page: "",
        slug: "",
      };
      this.links = [];
      this.productsAffiliate = [];

      let data = {
        list: true,
      };

      serviceProducts
        .search(data)
        .then((response) => {
          this.productsSeller = [];
          this.products = response;
          this.products.forEach((element) => {
            this.productsSeller.push({
              id: element.id,
              name: element.name,
              type: element.type,
              max_boleto_installments: element.max_boleto_installments,
              format: element.format,
              offers: element.offers,
              status: element.status,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchAffiliateProducts() {
      this.loading = true;

      this.index = null;
      this.product = {
        sales_page: "",
        slug: "",
      };
      this.links = [];
      this.productsSeller = [];

      serviceAffiliate
        .search({ per_page: 500 })
        .then((response) => {
          this.products = response.data;
          this.productsAffiliate = [];
          this.products.forEach((element) => {
            this.productsAffiliate.push({
              id: element.id,
              name: element.product.name,
              type: element.type,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCheckouts() {
      serviceCheckouts.search().then((response) => {
        this.checkouts = response;
      });
    },
  },

  mounted() {
    this.fetchSellerProducts();
    this.fetchCheckouts();

    // if (typeof this.$route.query.affiliation_id != "undefined") {
    //   this.tabSelect(1);
    //   setTimeout(() => {
    //     if (this.$route.query.affiliation_id) {
    //       this.setProduct();
    //     }
    //   }, 1000);
    // }

    // if (typeof this.$route.query.comingAffiliate != "undefined") {
    //   this.$bvToast.toast("Veja os links de divulgação", {
    //     title: "Afiliação",
    //     variant: "info",
    //     autoHideDelay: 5000,
    //     appendToast: true,
    //   });
    // }

    // if (typeof this.$route.query.orderbump != "undefined") {
    //   this.has_orderbump = true;
    //   this.tabSelect(0);
    //   setTimeout(() => {
    //     if (this.$route.query.p_id) {
    //       this.setProduct(false, true);
    //     }
    //   }, 1000);

    //   this.$bvToast.toast(
    //     "Para criar um Order Bump, selecione um produto e clique em 'Adicionar um link'",
    //     {
    //       title: "Order Bump",
    //       variant: "info",
    //       autoHideDelay: 5000,
    //       appendToast: true,
    //     }
    //   );
    // }

    // cupom
    if (typeof this.$route.query.c_id != "undefined") {
      this.setProduct("", true);
      const query = this.$route.query;

      this.has_cupom = true;

      this.cupom = {
        id: +query.c_id,
        name: query.c_name,
      };

      this.product = {
        id: +query.p_id,
        name: query.p_name,
        type: query.p_type,
      };

      this.$bvModal.show("links-new");
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>

.multiselect__option--disabled span {
  color: #ccc;

}
.order-bump-alert {
  display: inline;
  width: 18px;
  max-width: 100%;
  display: inline;
  margin-bottom: 7px;
  margin-left: 2px;
}

.link {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 5px;
  width: 100%;
}
.copy-link {
  width: 15px;
  /* margin-left: 20px; */
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}
.copy-link:hover {
  filter: invert(0);
}
.carregando {
  background: rgba(0, 0, 0, 0.03);
  color: transparent;
}
h6 {
  font-weight: 600;
  color: #002363;
}
.outros {
  padding-top: 30px;
  border-top: 1px solid #ededf079;
}
.outros-links {
  color: #333;
  font-size: 16px;
}
.wrapper {
  margin-top: -20px !important;
  z-index: 2;
}
.outro-item {
  margin-bottom: 7px;
}
.campanha_nome {
  margin: 0;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
.sub-titulo {
  font-weight: 600;
  color: #81858e;
  font-size: 14px;
}
.titulo-link {
  font-weight: 600;
  font-size: 14px;
}
.container-item {
  padding: 50px !important;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }
  .wrapper {
    padding: 30px 20px !important;
  }
  .container-item {
    padding: 30px !important;
    margin-bottom: 20px;
  }
  .tabs {
    margin: 40px 0 !important;
  }
  h6 {
    font-size: 15px;
  }
  .btn-adicionar {
    width: 100%;
    margin-top: -30px;
    margin-bottom: 10px;
  }

  .link-extra + .link-extra {
    margin-top: 15px;
  }
}
.p-editado {
  font-size: 14px;
  color: #81858e;
}
</style>
